<template>
  <!-- 
    画面: 掲載サイト編集
    用途: 掲載サイトを更新または削除する
   -->
  <div>
    <!-- #region Main -->
    <CRow>
      <CCol
        md="12"
        lg="12"
      >
        <CCard>
          <CCardHeader>
            <strong class="card-title">{{ $t("siteEditPage.pageTitle") }}</strong>
          </CCardHeader>

          <CCardBody>
            <CForm class="submit_form">
              <!-- #region 入力フィールド -->

              <!-- #region コード -->
              <CRow>
                <CCol class="label"><strong class="required-mark">{{
                    $t("siteEditPage.code")
                  }}</strong></CCol>
                <CCol>
                  <CInput v-model="edit.code" />
                  <p
                    class="text-danger input-info"
                    v-if="$v.edit.code.$dirty && !$v.edit.code.required"
                  >
                    {{
                      $t("validations.required", {
                        vName: $t("siteEditPage.code"),
                      })
                    }}
                  </p>
                </CCol>
              </CRow>
              <!-- #endregion コード -->

              <!-- #region 略称 -->
              <CRow>
                <CCol class="label"><strong class="required-mark">{{
                    $t("siteEditPage.shortName")
                  }}</strong></CCol>
                <CCol>
                  <CInput v-model="edit.shortName" />
                  <p
                    class="text-danger input-info"
                    v-if="$v.edit.shortName.$dirty && !$v.edit.shortName.required"
                  >
                    {{
                      $t("validations.required", {
                        vName: $t("siteEditPage.shortName"),
                      })
                    }}
                  </p>
                </CCol>
              </CRow>
              <!-- #endregion 略称 -->

              <!-- #region 名称 -->
              <CRow>
                <CCol class="label"><strong class="required-mark">{{
                    $t("siteEditPage.name")
                  }}</strong></CCol>
                <CCol>
                  <CInput v-model="edit.name" />
                  <p
                    class="text-danger input-info"
                    v-if="$v.edit.name.$dirty && !$v.edit.name.required"
                  >
                    {{
                      $t("validations.required", {
                        vName: $t("siteEditPage.name"),
                      })
                    }}
                  </p>
                </CCol>
              </CRow>
              <!-- #endregion 名称 -->

              <!-- #region URL -->
              <CRow>
                <CCol class="label"><strong>{{
                    $t("siteEditPage.url")
                  }}</strong></CCol>
                <CCol>
                  <CInput v-model="edit.url" />
                </CCol>
              </CRow>
              <!-- #endregion URL -->

              <!-- #region RSSアイテムリンクフォーマット -->
              <CRow>
                <CCol class="label"><strong>{{
                    $t("siteEditPage.rss")
                  }}</strong></CCol>
                <CCol>
                  <CInput v-model="edit.rss" />
                </CCol>
              </CRow>
              <!-- #endregion RSSアイテムリンクフォーマット -->

              <!-- TODO: 掲載サイト独自ポイント対応時に実装 -->
              <!-- #region 掲載サイト独自ポイント計算の利用 -->
              <!-- <CRow>
                <CCol class="label"><strong>{{
                    $t("siteEditPage.pointUse")
                  }}</strong></CCol>
                <CCol>
                  <CInputCheckbox
                    custom
                    class="mt-1"
                    :checked.sync="edit.pointUse"
                    :label="$t('siteEditPage.use')"
                  />
                </CCol>
              </CRow> -->
              <!-- #endregion 掲載サイト独自ポイント計算の利用 -->

              <!-- TODO: 掲載サイト独自ポイント対応時に実装 -->
              <!-- #region ポイント付与率設定 -->
              <!-- <CRow>
                <CCol class="label"><strong>{{
                    $t("siteEditPage.pointGrantRatePreference")
                  }}</strong></CCol>
                <CCol>
                  <CRow>
                    <CCol>
                      <CButton
                        class="px-3 mt-1 mb-2"
                        color="info"
                        @click="onAddClicked()"
                      >
                        <i class="icon cil-plus mr-1"></i> {{ $t("common.add") }}
                      </CButton>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol md="7">
                      <SiteEditPointTable />
                    </CCol>
                  </CRow>
                </CCol>
              </CRow> -->
              <!-- #endregion ポイント付与率設定 -->

              <!-- #endregion 入力フィールド -->

              <!-- #region 更新ボタン -->
              <CButton
                class="px-5 mt-4 float-left"
                color="info"
                @click="onUpdateClicked()"
                v-bind:class="activeStatus"
              >
                <i class="cil-task"></i> {{ $t("common.update") }}
              </CButton>
              <!-- #endregion 更新ボタン -->

              <!-- #region 削除ボタン -->
              <CButton
                class="px-5 mt-4 float-right"
                color="danger"
                @click="onDeleteClicked()"
              >
                <i class="cil-trash"></i> {{ $t("common.delete") }}
              </CButton>
              <!-- #endregion 削除ボタン -->
            </CForm>
          </CCardBody>

          <CCardFooter>
            <!-- #region 戻るボタン -->
            <CButton
              color="secondary"
              @click="onBackClicked()"
            >{{
              $t("common.back")
            }}</CButton>
            <!-- #endregion 戻るボタン -->
          </CCardFooter>
        </CCard>
      </CCol>
    </CRow>
    <!-- #endregion Main -->

    <!-- #region Delete confirm -->
    <DeleteConfirmModal
      :deleteConfirmModal="deleteConfirmModal"
      :callback="reqDelete"
      @close="deleteConfirmModal = $event"
    />
    <!-- #endregion Delete confirm -->

    <SuccessModal
      :successModal="successModal"
      @close="successModal = $event"
    />

    <ErrorModal
      :errorModal="errorModal"
      :errorMessage="errorMessage"
      @close="errorModal = $event"
    />
  </div>
</template>

<script>
// import SiteEditPointTable from "./components/EditPointTable";

import {
  required,
  // numeric,
  // minLength,
  // maxLength,
  // helpers,
} from "vuelidate/lib/validators";

export default {
  name: "SiteEdit",

  components: {
    // TODO: 掲載サイト独自ポイント対応時に実装
    // SiteEditPointTable,
  },

  computed: {
    /** 入力チェック */
    activeStatus() {
      return this.$v.edit.$invalid ? "inactive" : "active";
    },

    siteId() {
      return this.$route.params.siteId;
    },

  },

  data() {
    return {
      //#region Flag
      loading: false,
      successModal: false,
      errorModal: false,
      deleteConfirmModal: false,
      //#endregion Flag


      //#region Edit
      edit: {},
      //#endregion Edit


      //#region Response data
      response: [],
      //#endregion Response data


      //#region Error message
      errorMessage: "",
      //#endregion Error message
    };
  },

  validations() {
    return {
      edit: {
        code: { required },
        name: { required },
        shortName: { required },
        url: {},
      },
    };
  },

  methods: {
    //#region Event
    onBackClicked() {
      this.backPage();
    },

    onUpdateClicked() {
      this.$v.edit.$invalid ? this.$v.edit.$touch() : this.reqPut();
    },

    onDeleteClicked() {
      this.deleteConfirmModal = true;
    },

    onDeleteSubmit() {
      this.deleteRoomType();
    },
    //#endregion Event

    //#region Request

    /** データ取得 */
    reqGet() {
      const callback = (data) => {
        this.edit = {
          code: data.code,
          shortName: data.shortName,
          name: data.name,
          url: data.url,
          rssLinkFormat: data.rssLinkFormat,
          hasUsePoint: data.hasUsePoint,
          description: data.description,
        };
      };

      const errorCallback = (err) => {
        this.errorMessage = err;
        this.errorModal = true;
      };

      this.reqGetSite(callback, errorCallback);
    },

    /** データ更新 */
    reqPut() {
      const body = this.edit;
      const callback = () => this.successModal = true;
      const errorCallback = (err) => {
        this.errorMessage = err;
        this.errorModal = true;
      };

      this.reqPutSite(body, callback, errorCallback);
    },

    /** データ削除 */
    reqDelete() {
      const callback = () => {
        this.deleteConfirmModal = false;
        this.successModal = true;
      };
      const errorCallback = (err) => {
        this.deleteConfirmModal = false;
        this.errorMessage = err;
        this.errorModal = true;
      };

      this.reqDeleteSite(callback, errorCallback);
    },
    //#endregion Request


    //#region Method
    backPage() {
      this.$router.go(-1);
    },
    //#endregion Method
  },

  beforeMount() {
    this.reqGet();
  },

};
</script>